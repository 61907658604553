import React, { useContext } from "react";
import { ThemeContext } from "../../../ThemeProvider/ThemeProvider";

const Footer = () => {
    const { isDarkMode } = useContext(ThemeContext);

    return (
        <footer className={`${isDarkMode ? "bg-gray-900" : "bg-white"} py-2 absolute bottom-0 left-0 w-full`}>
            <div className="container px-4">
                <p className="text-sm text-gray-400 text-center">
                    ©2024 Designed and Developed by
                    <a className="text-blue-500"
                       href="https://github.com/singharaj-usai"
                       rel="nofollow">
                        {" "}
                        Singharaj Usai
                    </a>
                </p>
            </div>
        </footer>
    );
};

export default Footer;