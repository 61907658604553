import React from "react";
import EducationItem from "./EducationItem";

const educationData = [
    {
        id: 4,
        schoolpic: "https://www.logolynx.com/images/logolynx/f6/f65ff7d671f2fb7ce970f9ff59ba5e58.png",
        title: "Grand Valley State University",
        description: (
            <>
                <p style={{ textIndent: "1rem" }}>
                    • Grand Valley's Information Systems core classes includes, but not limited to: Computer Science 1 & 2, Visual Basic, Spreadsheets, Databases, Network Management, Internships, and more!
                </p>
                <p style={{ textIndent: "1rem" }}>
                    • Information Systems elective classes includes, but not limited to: Databases, Web App Programming, Software Development Tools, and more!
                </p>
            </>
        ),
        name: "Information Systems",
        date: "Aug 2023 - May 2025",
        button: "Visit School Website",
        link: "https://www.gvsu.edu/computing/information-systems-bs-43.htm"
    },
    {
        id: 3,
        schoolpic: "https://www.insidehighered.com/sites/default/server_files/media/trilogy-logo-2016-horizontal-for-light-bg_0.png",
        title: "edX Coding Boot Camp",
        description: (
            <>
                <p style={{ textIndent: "1rem" }}>
                    • Gained hands-on experience with HTML, CSS, JavaScript, SQL, & the MERN stack (MongoDB, Express.js, React.js, Node.js).
                </p>
                <p style={{ textIndent: "1rem" }}>
                    • Developed practical skills to build & deploy web applications using popular coding softwares & tools such as (but not limited to) Git, Jira, MySQL, Heroku, Apollo, MongoDB Atlas, & IntelliJ.
                </p>
            </>
        ),
        name: "Coding Bootcamp",
        date: "May 2022 - November 2022",
        button: "Visit School Website",
        link: "https://bootcamp.msu.edu/"
    },
    {
        id: 2,
        schoolpic: "https://s3.us-west-2.amazonaws.com/ycbm.production.upload.files/ycbm/dWsRhFGeavRSpuRzgxr3/images/egcc_new_top_logo.png",
        title: "Eastern Gateway Community College",
        description: (
            <>
                <p style={{ textIndent: "1rem" }}>
                    • Learned how to work in & lead teams, understand & prepare business & marketing plans, & identify the four functions of management (Planning, organizing, leading, and controlling).
                </p>
                <p style={{ textIndent: "1rem" }}>
                    • Courses learned include, but not limited to, Financial Accounting, Principles of Management, Micro and MacroEconomics, Securities and Investments, Business Communications, Managerial Accounting, and more!
                </p>
            </>
        ),
        name: "Business Management",
        date: "March 2020 - May 2022",
        button: "Visit School Website",
        link: "https://egcc.edu/"
    },
    {
        id: 1,
        schoolpic: "https://s3-us-west-2.amazonaws.com/scorestream-team-profile-pictures/7979/20230423201512_616_mascot720Near.png",
        title: "East Kentwood High School",
        description: (
            <>
                <p style={{ textIndent: "1rem" }}>
                    • Graduated from East Kentwood High School class of '17.
                </p>
                <p style={{ textIndent: "1rem" }}>
                    • 3 AP classes for college credit: AP Psychology, Environmental Science, and US Government.
                </p>
                <p style={{ textIndent: "1rem" }}>
                    • Elective courses: Graphic Design, Advanced Physical Education, Video Game Design.
                </p>
                <p style={{ textIndent: "1rem" }}>
                    • Clubs: Video and Film Club, Graphic Design Club.
                </p>
            </>
        ),
        name: "High School Diploma",
        date: "September 2013 - May 2017",
        button: "Visit School Website",
        link: "https://www.kentwoodps.org/ourschools/high-schools/east-kentwood/"
    }
];

const Education = () => {
    return (
        <section className="pb-4">
            <div className="flex justify-center flex-wrap ">
                {educationData.map((education, id) => (
                    <EducationItem education={education} key={id} />
                ))}
            </div>
        </section>
    );
};

export default Education;