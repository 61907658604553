import React, { useState, useEffect, useContext } from "react";
import image1 from "../../../../images/edx-boot-camps.png";
import image2 from "../../../../images/WRHjet.png";
import image3 from "../../../../images/Screenshot 2023-09-09 002454.png";
import bnr from "../../../../images/bnr.png";
import WebdevItem from "./WebdevItem";
import { ThemeContext } from "../../../../ThemeProvider/ThemeProvider";
import Pagination from "react-paginate";

const NUMBER_ITEMS_PER_PAGE = 2;

const webdevData = [
    {
        id: 2,
        image: image2,
        title: "Project Bronze",
        description: (
            <>
                <p>
                    Developer of <b>Pokemon: Project Bronze</b>, a Roblox game developed using Lua with an average of <b>4,000+ daily active users</b> and over <b>100 million players</b> data stored using Firebase.
                </p>
            </>
        ),
        technologies: ["Lua", "Firebase", "Nginx", "3dsMax"],
        btnlink4: "https://www.bronzeforever.net/",
        btntype4: "Play Now",
    },
    {
        id: 3,
        image: bnr,
        title: "Build and Race",
        description: (
            <>
                <p>
                    Developer of a sandbox building game known as <b>Build and Race</b> on the Roblox.com platform, with the goal to revive a niche that was once popular from 2010-2017. Experience has a total of <b>80,000+ plays</b> and <b>200+ favorites</b>.
                </p>
            </>
        ),
        technologies: ["Lua", "MongoDB", "Photoshop"],
        btnlink4: "https://www.roblox.com/games/6441890716/obama",
        btntype4: "Play Now",
    },
    {
        id: 1,
        image: image3,
        title: "Car Rental App",
        description: (
            <>
                <p>
                    • Open-source car rental website developed using Next.js and TailwindCSS for the front-end. The back-end uses Prisma ORM and MySQL to handle vehicle databases and user authentication.
                </p>
                <p style={{ textIndent: "1rem" }}>
                    • My project has over <b>100+ GitHub stars</b> and <b>40+ forks</b>, and is actively maintained by the GitHub community with frequent pull requests.
                </p>
            </>
        ),
        technologies: ["Next.JS", "Tailwind CSS", "Prisma", "MySQL", "Vercel Deployment"],
        btnlink2: "https://github.com/singharaj-usai/car-rental-app",
        btntype2: "View Source Code",
        btntype1: "View Demo",
    },
    {
        id: 4,
        image: image1,
        title: "edX Coding Bootcamp Projects",
        description: (
            <>
                <p style={{ textIndent: "1rem" }}>
                    • A collection of all my Coding Bootcamp projects and assignments I have done during my time at EdX's Coding Bootcamp.
                </p>
                <p style={{ textIndent: "1rem" }}>
                    • These projects include things like OOP Team Profile Generator, Node.JS YouTube To MP3 Converter, Express.JS Note Taker, MongoDB Social Network APIs, MERN Google Book Search Engine, and more!
                </p>
            </>
        ),
        technologies: ["JavaScript", "npm", "jQuery", "AJAX", "OOP", "ORMs", "MySQL", "MongoDB", "MERN", "Redux"],
        btnlink1: "https://github.com/singharaj-usai",
        btntype1: "Coding Bootcamp Repo",
    },
];

const Webdev = () => {
    const { isDarkMode } = useContext(ThemeContext);
    const [currentPage, setCurrentPage] = useState(0);
    const [numberItemsPerPage, setNumberItemsPerPage] = useState(NUMBER_ITEMS_PER_PAGE);

    const handlePageClick = (data) => {
        setCurrentPage(data.selected);
    };

    useEffect(() => {
        const handleResize = () => {
            if (window.matchMedia("(min-width: 375px)").matches) {
                setNumberItemsPerPage(2);
            } else {
                setNumberItemsPerPage(1);
            }
        };
        window.addEventListener("resize", handleResize);
        handleResize();
        return () => window.removeEventListener("resize", handleResize);
    }, []);

    const prevNextTextColor = isDarkMode ? "text-white" : "text-gray-600";
    const pageNumberTextColor = isDarkMode ? "text-gray-50" : "text-gray-800";
    const activePageNumberTextColor = "text-white";

    return (
        <section className="pb-10">
            <div className="flex justify-center flex-wrap">
                {webdevData
                    .slice(currentPage * numberItemsPerPage, (currentPage + 1) * numberItemsPerPage)
                    .map((webdev, id) => (
                        <WebdevItem webdev={webdev} key={id} />
                    ))}
            </div>
            <Pagination
                className="flex justify-center"
                pageCount={Math.ceil(webdevData.length / numberItemsPerPage)}
                initialPage={currentPage}
                onPageChange={handlePageClick}
                pageRangeDisplayed={2}
                marginPagesDisplayed={2}
                previousLabel={
                    <span className={`relative px-3 py-1 font-medium rounded-md ${prevNextTextColor}`}>
                        Previous
                    </span>
                }
                nextLabel={
                    <span className={`relative px-3 py-1 font-medium rounded-md ${prevNextTextColor}`}>
                        Next
                    </span>
                }
                breakLabel={<span className="relative px-3 py-1 font-medium text-gray-700 rounded-md">...</span>}
                pageClassName="mx-2"
                breakClassName="relative font-medium text-gray-700 rounded-md"
                pageLinkClassName={`px-2 py-1 rounded-md text-sm ${pageNumberTextColor}`}
                previousLinkClassName="px-2 py-1 rounded-md text-sm"
                nextLinkClassName="px-2 py-1 rounded-md text-sm"
                activeClassName={`bg-blue-500 ${activePageNumberTextColor}`}
            />
        </section>
    );
};

export default Webdev;
