import React, { useEffect, useState } from "react";
import "./Preloader.css"; // Import your CSS file

const Preloader = () => {
    const [loading, setLoading] = useState(true);

    useEffect(() => {
        const timer = setTimeout(() => {
            setLoading(false);
        }, 1200); // You can adjust the time as needed

        return () => clearTimeout(timer);
    }, []);

    return (
        <div className={`preloader ${loading ? "show" : "hide"}`}>
            <div className="preloader-inner">
                <div className="preloader-icon">
                    <span></span>
                    <span></span>
                </div>
            </div>
        </div>
    );
};

export default Preloader;
