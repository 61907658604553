import oldrobloxcrossroads from '../../../images/RobloxScreenShot01082012_155608039.jpg';
import guysprofile from '../../../images/myroblox.png';
import codebootcampcert from '../../../images/Code Boot Camp Certificate.png';
export const blogData = [


    {
        id: 4,
        img: "https://i.ytimg.com/vi/my6aOHv0zeM/maxresdefault.jpg?sqp=-oaymwEmCIAKENAF8quKqQMa8AEB-AH-CYAC0AWKAgwIABABGC4gZSg-MA8=&rs=AOn4CLBXSmow8OpQR3Ite71kke8_270XbQ",
        slug: "my-plans-with-an-old-roblox-project",
        title: "My Plans With An Old Roblox Project",
        date: "October 14, 2023",
        description: "My current plans I have with my old Roblox website",
        content: `
I have been working on an old Roblox revival website called Bloxtopia (**just a placeholder name for now, don't judge**) for a few weeks now, which is the reason why you guys have seen a pause in me reuploading Pokemon Brick Bronze for a while.
I restarted this old Roblox website from scratch recently because the original code was hideously written without any planning or structure, it was a complete mess.

Anyways, there has been this noticeable absence in the old Roblox community for a few years now where it's sort of been forgotten or abandoned because there's no longer a sizable community that places like Finobe or Graphictoria once had.

There's this big space that I feel like it should be filled and revived. With the success of Project Bronze, I feel like I could revive the ORC (**Old Roblox Community abbreviation**) with the experience I have in community building and my recently gained coding experience.

Joining back in 2008 as a kid I was always interested in old Roblox related things, and I genuinely miss the vibe Roblox once had.
I even had an old Roblox Crossroads 2006 simulator game back in 2012 that was semi-popular on my old account, see the image below!

<img src="${oldrobloxcrossroads}" alt="Screenshot of 2012 Crossroads" />

Does this look cool???

I always knew there was a need for classic Roblox stuff for people to experience, but there wasn't really a proper place to find them since sites like Graphictoria, Finobe and others are long dead and defunct.

You could say there are sites like Austiblox, Meteorite or some other obscure places, but it's a ghost town.
It's not the same playing alone.

There are simulator games like [Super Nostalgia Zone](https://www.roblox.com/games/998374377/Super-Nostalgia-Zone) and [Retrostudio](https://www.roblox.com/games/5846386835/RetroStudio), but those games are just simulators.
They don't capture the actual gameplay and feel of what old Roblox was because they're just remakes on the Roblox platform.

There are lots of patched clients I found, so most of the hard work was already finished.
There are of course a lot of work that need to be done with them to patch security vulnerabilities and other things, but this should get us up and running a lot sooner.

So my plan is to build an old Roblox website from scratch using the MVC file structure, React.JS and I would imagine a lot of GraphQL APIs, and use some open-sourced 2010-2018 Roblox clients for the site.
This way, people will be able to experience the true old Roblox because the clients are actual old Roblox files, and not a simulator.

#### Questions

There's these questions that I'll need to go over when I'm building the website as time goes on.

1. Do I want users to upload games, or will it just be only me uploading them to prevent trolls and the headaches of moderating?
2. How long will this take??? (**Definitely a few months...**)
3. What's the website style layout gonna be like?
* Answer: **I personally liked the 2013 layout, it might resemble something like that, I don't know though**

<img src="${guysprofile}" alt="Screenshot of 2012 Crossroads" />

3. Bring back Tickets and forums?
* Answer: **YES!**

I do have some personal reasons for wanting to have a bootleg Roblox website, but I'll keep that a secret for now.


`

    },

    {
        id: 3,
        img: "https://edgy.app/wp-content/uploads/2020/03/youtube-homepage.jpg",
        slug: "should-i-come-back-to-youtube",
        title: "Should I Come Back To YouTube?",
        date: "August 9, 2023",
        description: "Some self-sanity check on if I should return to YouTube.",
        content: `
I always wanted make a career doing something online, and its become a lot easier to do that nowadays.
That's where YouTube comes in.

I watched tons of people on YouTube who became successful making gameplay videos, commentaries, etc. on YouTube for random people to watch and wanted to be like them.
That's where my inspirations came from.

If I were to come back to YouTube and make videos again, this would be like the 4th time I would return.

The feeling of making someone's day positive was the main reason I continued making YouTube videos.

But do I **REALLY** want to make videos again?
I recorded and already edited and finalized these videos on my backlog ready to be uploaded, but without the audience I once had I didn't find a reason to continue.

I started making these Roblox videos in 2015 when I was still in high school 10th grade and my first channel reached 100k subscribers in 4 months.

In total, I had around 3 million subscribers over the years on these channels.

I never told anyone in school that I was a "YouTuber" because I didn't know how people would react.
There is this stigma of not doing something traditional like being employed by someone to do specific tasks.

And once people find out the type of videos you make, that's all people will know you for and they wouldn't take you seriously anymore. Most people also don't consider being a social media creator "a real job" because it's not something traditional, and you're not paid a W2, it's all 1099 paid by Adsense so when you try to get a loan they will see this as a risk. Try convincing other people that this is a real job when they sometimes envy something like this, it's also difficult to put stuff like this on a resume and have it taken seriously.

Take my [5th YouTube channel](https://youtube.com/channel/UClVJA8pwpfDcJZjdboeLTXg) for example (rest in peace). I primarily made Pokemon Brick Bronze videos as well, some got millions of views back in 2017. These videos were highly animated and took a long time to edit to keep audience retention.

But if you are inconsistent with uploading, YouTube's algorithm will punish you by making your new videos rank lower in the search algorithm and sometimes your subscribers will not be notified when you upload, even with the notification bell turned on. My CPM, click-through ratio and RPM revenue per 1,000 views were pretty good, but it is not enough to live off on.

And also, I'm doing too many things at once so I need to focus on doing something instead of doing all of this at the same time. So if I want to get into tech as a career to make a living off on, build portfolio projects to show my skills and get the degree required to get into tech, I would have to reduce time spent doing social media and YouTube or even sacrifice it temporarily to find the time to work on coding projects and college assignments because working on YouTube was a full-time job. You also had to treat YouTube as a full-time job because the algorithm would punish you for taking a few days off from not uploading.

My old YouTube friends like NapkinNate, Kazok, xBuzzerman, etc also stopped doing YouTube to focus on different careers because this isn't sustainable. And these people had millions of subscribers as well, or were about to reach 1M+ before stopping to do something else.

#### My process of making a video

The process went kinda like this:

1. Thinking about what videos to make
2. Recording the videos on OBS and sometimes Xsplit (for livestreaming)
3. Editing the videos on Vegas Pro or Premiere Pro and getting the edits just right with some animations to retain audience and encourage people engage with the video by liking and subscribing and other stuff
4. Thinking of how to make the perfect thumbnail on Photoshop which took hours to do, and putting in the right keywords in the tags section of the upload
5. Making sure all the keywords and tags made the video ranked as suggested by VidIQ and Tubebuddy in order for the new uploads to rank as high as possible in the search page of the video topic.

These took hours and days to do and was very tedious, and I figured it wasn't worth the effort anymore.

Maybe one day I'll find a way to return to making YouTube videos again. It probably won't be Roblox videos though.

`
    },

    {
        id: 2,
        img: "https://th.bing.com/th/id/OIP.1hGEOIaK47NQdtx9yorfygHaD_?rs=1&pid=ImgDetMain",
        slug: "coding-bootcamp-2023-review",
        title: "Coding Bootcamp 2023 Review",
        date: "July 20, 2023",
        description: "In this post, I'm giving my experience with edX’s Coding Boot Camp",
        content: `


I finished my Coding Boot Camp class in November 2022 (our classes were held on Zoom). Since then, I have gotten lots of questions from people interested in Coding Boot Camps.

<img src="${codebootcampcert}" alt="Code Boot Camp Certificate" />

I wrote down a bunch of things I experienced during my time in Code Boot Camp to gather some information on what edX’s Tech Boot Camps real strengths are, and where they could do better.

#### Are code boot camps for beginners?

For a short background of myself, I ran popular YouTube channels for a few years but it's no longer sustainable to live off on due to YouTube's new rules for monetizable content. After doing some self-reflection on what I wanted to do outside of my YouTube career, I found out I had an interest in Web Development so I researched into Coding Boot Camps since they were suggested as a good way to get into tech without having to go to college.

I came into this as a complete beginner to coding. My only experience with coding is with making Roblox games (I run some well known Roblox games and have been making Roblox games in my free time for years).

I remember back in 2020 and 2021 when everything was remote, companies were mass advertising their coding schools like [Flatiron School](https://www.youtube.com/watch?v=hJATZsyJi8E), and [Google Career Certificates](https://www.youtube.com/watch?v=Wu37DTSbt8s).

The Boot Camp that I decided to go with is [edX Boot Camps](https://www.edx.org/boot-camps), formerly [Trilogy Education Services](https://web.archive.org/web/20200527055940/https://www.trilogyed.com/) because they were the most affordable ($13K+ plus $1,000 down-payment). 

Is coding bootcamps good for beginners? Coding bootcamps is very fast paced. Every new class day you're moving on and learning something completely new. If you're a fast learner and able to retain over at least half of what you learned, coding bootcamps might be good option to start becoming a web developer from scratch quickly without needing to go to college for a Computer Science degree (which teaches you pretty much everything in computing, but web development seems to be missing from most CS courses).

#### What to know before code boot camp

edX advertises their Boot Camps as [for beginners](https://www.edx.org/boot-camps), but I want to say this is not an “intro to coding” course. The course only briefly covers the basics of coding such as control structures, back-end development, OOP, etc. The course material is packed with lots of information, but skims over them so the instructor can cover all the material within 6 hours for the day.
 
To save you a lot of headaches, if you want to pay for a course like this, here is what I think you should be familiar with before enrolling into a Coding Boot Camp:
 
* **Knowing CSS box model.** Our class covered the [box model](https://www.w3schools.com/Css/css_boxmodel.asp) on our 3rd day. Knowing the box model is important because this is also when you learn about wireframing, positionings, flexboxes, and responsive designs.

* **Learn how to use colors in CSS.** They didn't teach us how to use [hex](https://www.w3schools.com/css/css_colors_hex.asp), [RGB](https://www.w3schools.com/Css/css_colors_rgb.asp) and [HSL](https://www.w3schools.com/css/css_colors_hsl.asp) in our course. This area was completely missing, and knowing how to use color pallets, gradients, proper color combinations, and stuff like that is very important to make your websites stand out and user-friendly.

* **Learn Git.** You will be using [Git](https://git-scm.com/downloads) (or a version control similar to Git) a lot in a professional setting, the course teaches us Git near the end of every class but it didn't click enough until I started learning it on my own. Knowing Git is important since it's what you will be using to contribute in open-source projects and collaborations, and to also save and publish your work.

* **Know basic JavaScript.** You should at least know how to assign variables, do basic for loops, and do local and global scopes so you can be prepared for the class.

#### What is the curriculum like?

Pretty much every Coding Bootcamps will be teaching you JavaScript, the only difference is how much information they might throw at you in 6 months. Here's an archive of [Trilogy Education Services' Programs Syllabus Web Page](https://web.archive.org/web/20200920075252/https://www.trilogyed.com/programs/#web-curriculum) (Trilogy Education Services was the company that ran the program before edX purchased and merged with them). And here is the current 2023 syllabus from edX's website: https://www.edx.org/boot-camps/coding.

* **Any admission tests to be considered?** There is a technical test they give you before you enroll, which is just to make sure you're not a complete computer noob, no coding assessments.

* **What you'll be learning.** Here's exactly what my Boot Camp covered week by week:
 * Week 1: HTML, Git, CSS
 * Week 2: Advanced CSS
 * Week 3: JavaScript
 * Week 4: Web APIs (DOM, Local Storage, Window Objects)
 * Week 5: 3rd Party APIs (jQuery and Bootstrap)
 * Week 6: Server-Side APIs (Fetches and Server-Side Events)
 * Week 7-8: Group Project 1
 * Week 9: Node.JS
 * Week 10: Object-Oriented Programming (TDD and ES6)
 * Week 11: Express.JS
 * Week 12: SQL
 * Week 13: Object-Relational Mapping (Sequelize and Authentication)
 * Week 14: Model-View-Controller
 * Week 15-16: Group Project 2
 * Week 17: Computer Science (Data Structures and Algorithms)
 * Week 18: NoSQL (MongoDB)
 * Week 19: Progressive Web Apps
 * Week 20: React
 * Week 21: MERN (GraphQL and JWT)
 * Week 22: State Management (Redux)
 * Week 23-24: Group Project 3
 * Week 25: Mock Interviews and Continuation Courses: (Java, Python, AWS & C# .NET)

* ** Coding Boot Camp is VERY fast-paced!** There is such a thing as Bootcamp Dropouts! This shouldn't be surprising, but since Coding Boot Camps are mostly under 6 months it's very fast pace and easy to fall behind. We started with around 40 people in my class, and only around 10 of us made it to the end! Most people were not able to keep up with how fast class went, if you're not able to be a fast learner then Coding Boot Camp might be a bad idea for you due to how fast it is! If you fall behind, it's very hard to catch up.

* **Technical Interview Preps.** The Boot Camp did not focus much at all on passing technical interview tests. It focused much more on practical programming than theory. I wish the bootcamp covered more on Algorithms and Data Structures because those are pretty much guaranteed when you're being screened for interviews like those HackerRank online assessments before you get to speak to a person.

* **There's no "required reading".** edX's goal is to make sure you understand how to read documentation and understand how and why code works. Each week, the instructor and teaching assistants will share lists of documentations and articles to read. You can **probably** make it through the course without reading these, but if you really want to think and work like a developer, well... you should read them. Reading documentations will help you start understanding how code works.

#### Will edX's Boot Camps help me get a job?

Tldr; no. I can confidently say that too since only 10 of us made it to the end and no one managed to get a tech job. Everyone else seemed to have quit coding completely (assuming they did by looking at their GitHub activities and LinkedIns). I'm apparently the only one left still doing anything with coding.

This is where the problems start with coding Boot Camps.

* **You're competing against a lot of people.** The tech job market is very competitive with Code Boot Camp grads, self-taught programmers, and new college grads.
 * Many companies are old school, meaning they only consider people with degrees for the first tech job, which is rigged in my opinion. So just know you're at a disadvantage if you don't have a degree or work experience. Think about this, they have a bootcamp grad and a college grad resume, who do you think they will give an interview to?
 * You're also competing with people who have the traditional Computer Science degree, while your only credentials you have is that Boot Camp certificate and a few projects that no one cares about **(assuming you don't have a college degree)**, which means basically nothing to employers who only give interviews to people with degrees or relevant work experience. 
 * **Lot's of places don't care about coding projects, they care more about relevant work history**. Coding projects are used as talking points in interviews if you have nothing relevant to talk about. Most people who do coding bootcamp already have years of work experience so they use the coding projects with their work history for interviews since they're career switchers.
 
#### The problem with Coding Bootcamps

* **Bootcamp Projects.** Honestly, the projects bootcamps make you do are not enough to get you a job. The projects are very basic and don't really show off your skills. They're just there to show you understand what you learned in class, these bootcamp projects are not resume worthy and are not going to get you a job. You need to have high quality personal projects that are relevant to the job you're applying for to stand out from other people who have years of experience or degrees jobs are looking for. You need to work on your own personal projects after bootcamp to showcase your skills, you might have been better off making your own projects on your own time and not doing the bootcamp in the first place due to these cookie cutter projects bootcamps assigned you to do and artificial deadlines causing these bootcamp projects to be low quality.

* **Projects Alone Are Not Enough.** You'll have a better experience doing freelance projects than job hunting. If you are finding your first legit job, and you don't have a degree or work experience and all you have are projects, you will have an extremely tough time landing something. Having high quality personal projects can make you stand out, but you need more than just projects to stand out from other people who have years of experience or degrees jobs are looking for. Those self-taught programmers who landed developer roles were able to mostly because they already had years of work experience, so having that degree for your first real job will help you a lot. **The degree is just to get your resume through job application filters** that HR people put in place, and for internships to get experience on your resume.

* **Applying To Jobs With No Degree/Relevant Work Experience In 2023/2024.** When you're applying to jobs in general, there are filters that HR people put in place to limit the amount of resumes that make it through due to the amount of people applying for these jobs, this includes filtering out resumes that don't have relevant or no degrees, less than 3 years of relevant work history, etc. When you don't have either of those on your resume and all you have are projects, your resume is not even going to be seen since lots of places automatically trash resumes that don't meet their requirements. To bypass this, some coding bootcamps are **now using their projects as company work experience** and have the bootcamp be used as reference, and lots of companies are now catching on to this leading to resumes with coding bootcamps being removed entirely with no chance of being interviewed. Most of the time we're not even getting those automated OAs (Online Assessments) because of this. **You're severely limiting your opportunities** by not having the degree since these places are now treating college degrees the same way as work history, it's a bare minimum to have a degree to be taken seriously nowadays. Also good luck trying to talk to HR about coding projects because they usually have no idea about anything to do with tech, and they're the first people you'll talk to if you do manage to get an interview.

* **The Coding Boot Camp certificate.** No one cares that you did a Coding Bootcamp. The certificate you receive after coding bootcamp is just a PDF showing you went through and completed their course. The only people who care about the bootcamp certificate is the bootcamps themselves so they may be able to hire grads to become Teaching Assistants for their next classes.

* **Your Bootcamp Might Shutdown.** Coding Bootcamps are struggling since the hype from 2017-2020 is long gone. Lots of popular Bootcamps have closed down like Hack Reactor, Kenzie Academy, Dev Bootcamps, etc. I have a friend whos coding bootcamp (Lambda School) suddenly shutdown near the first month of the class, wasted $1K for the downpayment and 2K+ for tuition.

* **Almost non-existent Career Services.** Trilogy Education does not assist with job hunting. Once you're done with the bootcamp, you're on your own. They have these career events on Zoom calls once every few days that felt useless. They're just telling you things you already knew because the coding mentor told you these information in class. It's things like ["Life After Coding Bootcamp"](https://careernetwork.2u.com/all-events/page/2/?event_type=past) and ["Interview Prep For Web Developers"](https://careernetwork.2u.com/events/coding-interview-prep-2/), things you can easily look up on Youtube. They have a resume feedback on their career services but it's a waste of time honestly, all the feedbacks they give are things like **"Just be yourself!"** or **"It's okay to have imposter's syndrome, keep continuing to push forward!"**. What the Career Advisor gave as a feedback was a copy and pasted response of things I already had on my resume.. No mentions about formatting or bullet points or keeping the resume less than 2 page and being ATS-friendly, etc. And nothing about their Career Connections thing they supposedly once had with companies to do recruiting with. Their "Career Services" was legit just a [job board](https://cen.develop.twoyou.co/job-board/) that anyone can pull an API from and claim "We work with these companies to hire people like you!". When you think of Career Services, you think of 1 on 1 career assistance and job hunting with help from the bootcamp, this felt like an actual scam and ripoff. Definitely not worth the $15k price tag.

* **College Degree VS Coding Bootcamps.** Yes college grads are struggling to land something, but at least their resumes are making through HR filters because they have the degree listed on their resume HR requires as a minimum. If all you have is that coding bootcamp and projects and irrelevant work history on your resume, your resume is not even making it through, and coding bootcamps are not making the stuff that are working against you clear, you find this out only after finishing the course. You would be lucky if the person you're talking to has ever heard of a coding bootcamp before, college is the traditional way to be taken seriously and it's what people expect you to go through.

#### Is Coding Boot Camp right for you?

Everything coding bootcamp teaches you can be learned for free on the internet on websites like [The Odin Project](https://www.theodinproject.com), [App Academy Open](https://open.appacademy.io/), and [freecodecamp](https://www.freecodecamp.org). The reason why people pay so much money on coding bootcamps is for the bootcamp's claim to provide career service to help them land a tech job after completing the bootcamp, and EdX Bootcamps didn't deliver that. EdX's 2U Career Services is a complete scam, it was literally [just a website](https://careernetwork.2u.com) anyone can get access to with generic advice, this is the scam 2U justified people paid $15k+ for. You only find this out after finishing the bootcamp when the Student Success Manager sends you this link and that's your career service...

So it's going back to school for me, almost like the coding bootcamp was pointless. Since I'm a transfer student and almost all of my gen-ed classes credits transferred, all I need left to do mostly are the CS classes and I'll be graduating before 2025 at the max, so I might as well get the degree just to check the boxes on job application requirements to qualify.

The bachelors degree is just 1 less thing people can use against me when I apply for jobs. Resumes that don't have a bachelors degrees or relevant work experience are not making through because why would they choose a bootcamp grad over someone with a college degree and internships. I believe I have the skills, but not the required degree yet that employers in Michigan are demanding to have for the first real job, so that's my reason for going back to school. 

Most people haven't even heard of what a coding bootcamp is, so they're not going to take it seriously when they see it on your resume. There's a well-reputated coding bootcamp company called [Codesmith](https://www.codesmith.io/) that helped people get into Facebook, Google and Microsoft before **(comes with a $21k price tag if you have the money)**, but that's not what happens anymore. Even Codesmith grads aren't getting tech jobs now. 

If you heard of [ITT Tech](https://en.wikipedia.org/wiki/ITT_Technical_Institute) before, that's actually what a lot of these bootcamps are like, you're paying so much money on these bootcamps for their promises of providing you career services thinking they can help you get into tech but they're not taken seriously by anyone. And some bootcamps end up being complete ripoffs like 2U trying to justify their price for a simple [generic website](https://careernetwork.2u.com) as their career service that has no real value. If you could tell, yes I'm butthurt, all of this money and time spent only for the bootcamp to rip us off and giving false promises.

If you're serious on enrolling in a coding Boot Camp **(I highly recommend you do not)**, make sure the company you're looking into has a data on [cirr.org/data](https://cirr.org/data), which reports Bootcamp Outcomes data for transparency reports. edX does not participate in this, so pretty much avoid programs that don't report outcomes is my best advice for you.

Hopefully this gives you some insight! If you have any questions, feel free to connect and [send me a message on LinkedIn](https://www.linkedin.com/in/singharaj-usai), or you can send me an email from the Contact form on my website. 

`

    },


    {
        id: 1,
        img: "https://images-wixmp-ed30a86b8c4ca887773594c2.wixmp.com/f/1398746b-8c44-4a98-8939-5051a027a440/dfa08v9-3d6fb594-20e3-4a31-9979-c4d21bbc59d6.jpg/v1/fill/w_1192,h_670,q_70,strp/pokemon_brick_bronze_logo_2018_remake_by_realmrbobbilly_dfa08v9-pre.jpg?token=eyJ0eXAiOiJKV1QiLCJhbGciOiJIUzI1NiJ9.eyJzdWIiOiJ1cm46YXBwOjdlMGQxODg5ODIyNjQzNzNhNWYwZDQxNWVhMGQyNmUwIiwiaXNzIjoidXJuOmFwcDo3ZTBkMTg4OTgyMjY0MzczYTVmMGQ0MTVlYTBkMjZlMCIsIm9iaiI6W1t7ImhlaWdodCI6Ijw9NzIwIiwicGF0aCI6IlwvZlwvMTM5ODc0NmItOGM0NC00YTk4LTg5MzktNTA1MWEwMjdhNDQwXC9kZmEwOHY5LTNkNmZiNTk0LTIwZTMtNGEzMS05OTc5LWM0ZDIxYmJjNTlkNi5qcGciLCJ3aWR0aCI6Ijw9MTI4MCJ9XV0sImF1ZCI6WyJ1cm46c2VydmljZTppbWFnZS5vcGVyYXRpb25zIl19.vOQUd1RhlVyhGV0KNgGhA9651vGtFNSkIl5vw7_LSj0",
        slug: "switching-pokemon-brick-bronze-from-firebase-to-mongodb",
        title: "Switching Pokemon Brick Bronze From Firebase To MongoDB",
        date: "February 21, 2023",
        description: "Why I'm planning to switch Pokemon Brick Bronze's data service to MongoDB after using Firebase for 3 years.",
        content: `
#### A small introduction

As some of you may know, I am the developer of Project Bronze. Project Bronze is a revival project of a game on the Roblox platform that existed from around 2014 to 2018. 

I started this project in 2019 because I wanted to relive those best moments and share it with people when Roblox games weren't just lazy copy-and-paste cash grab simulators.
I also had some Roblox game development experience before this and fixed the game's old codes to an up-to-date playable version.

In the beginning when I started this, the game didn't have external data saving. 
This meant that whenever players saved their progress, it was stored on Roblox's servers called **Data Store**. 
On paper, this seems like a good thing since you don't have to pay for the servers and storage, and since it came out of the box from the game.

The bad thing about this was that you couldn't migrate user's data out of the game. 
This part becomes important later.

We started this project with a copy of the Gym 6 update from 2017 that was uploaded on Roblox due to a security breach that allowed tons of popular games to be released.
And I found out I had the game in my computer for a long time, and started saving all of the decals to my computer for some reason. 
Then a month later in May, the original owners of the game started deleting the decals on Roblox which caused the game to be unplayable for months. 

Luckily for us, I had already saved all of the decals so I started reuploading them all and got the game to a working condition again.

Anyways, with that introduction out of the way, let's get into the problem with using Roblox's Data Store.

#### The problems with Roblox Data Store

Ask any Roblox developers and you'll find out that most of them like using Roblox's Data Store because it's free and easy to use. 
But you are restricted to Roblox's ecosystem, which means that you cannot retrieve player's data and ship it to off-platform.

This also means that if you had thousands of people playing your game and you were to ever get banned including the game, all of that data can never be transferred out and would be permanently lost.

That's where the story ties to Project Bronze. Roblox has been banning these reuploads for years already, most of which have been using Roblox Data Store and not using a 3rd party external database. 
For a long time, no revivals have been using external databases which created angry players every time Roblox banned these games because they would lose all of their progress with the game being banned.

Using a database like Firebase, Trello or MongoDB basically guarantees that if Roblox were to ban your game, your player's data's are in safe hands as it's not stored in Roblox's servers.

A few people have already made a Roblox-to-Firebase Database module, so the hard part was already made for us. All that had to be done was connecting it to the game, which we paid a freelancer to do.

#### Unexpected problems with Firebase

Converting the game to use Firebase was a genius idea, but it came with an unexpected problem.

Firebase is notorious for being an expensive option for databases.
With thousands of players constantly saving and loading their progress, and us using Firebase's Blaze Plan, this became very expensive.
We were billed about $600 a month for on average 3,000 players playing at a given time.

Now, you might be saying that we just configured Firebase improperly, and if configured correctly it would be the most affordable cloud database.
This would be true if it weren't how the game was built. 
Come to find out that remaking the game's save codes would be a nightmare because of how old the game is and would break a bunch of features.

And also Firebase's servers are known to crash frequently, you definitely don't want this with thousands of people playing your game.

Also add on to the fact that any bugs the regular player might encounter will be extremely annoying because on the Discord, that's all they do, complain about how there's a glitch but never explain how the glitch happened.

#### Experimenting with MongoDB

Some of you may not know, but I attended a coding bootcamp and graduated in November 2022 (YAY!). 
Before doing the bootcamp, I knew very little about coding beyond Roblox. 
I'll make a blog post about my bootcamp's experience soon though.

My coding bootcamp actually has a MongoDB module and it looks very useful to convert Firebase to MongoDB.
With my class material on MongoDB, I believe I can make a well optimized code for the game to reduce the cost of using an external database and to finally leave Firebase behind.

All of these other revival projects are using Firebase as well with very expensive bills.
So hopefully with this transition, this will be cost-effective and with the knowledge I gained from my coding academy, I can make user's experience a lot better and also build my experience with a new technology too.

#### You made it to the end!

In this post, I have explained:

1. The humble beginnings of Project Bronze.
2. The problems with Google's Firebase.
3. My solution to the problem.

**Thanks for reading.** I hope you learned something useful.

`
    },
];