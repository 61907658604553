import React, {  useState, useEffect } from "react";
import funnyPic1 from "../../../funnypics/carl.png";
import funnyPic2 from "../../../funnypics/purepng.com-cat-cuteanimalscats-115205020723cicy.png";
import funnyPic3 from "../../../funnypics/star.png";

const funnyPics = [funnyPic1, funnyPic2, funnyPic3];

const About = () => {
  const [randomPic, setRandomPic] = useState(null);

  useEffect(() => {
    const getRandomPic = () => {
      const randomIndex = Math.floor(Math.random() * funnyPics.length);
      return funnyPics[randomIndex];
    };
    setRandomPic(getRandomPic());
  }, []);

  return (
    <section className="relative py-8">
      <div className="flex flex-wrap md:px-4 justify-center">
        <div className="w-full md:w-2/4">
          <div className="flex p-4 mb-4 text-sm text-red-800 border border-red-300 rounded-lg bg-red-50 dark:bg-gray-800 dark:text-red-400 dark:border-red-800"
            role="alert">
            <svg
              className="flex-shrink-0 inline w-4 h-4 mr-3 mt-[2px]"
              aria-hidden="true"
              xmlns="http://www.w3.org/2000/svg"
              fill="currentColor"
              viewBox="0 0 20 20"
            >
              <path d="M10 .5a9.5 9.5 0 1 0 9.5 9.5A9.51 9.51 0 0 0 10 .5ZM9.5 4a1.5 1.5 0 1 1 0 3 1.5 1.5 0 0 1 0-3ZM12 15H8a1 1 0 0 1 0-2h1v-3H8a1 1 0 0 1 0-2h2a1 1 0 0 1 1 1v4h1a1 1 0 0 1 0 2Z" />
            </svg>
            <div className="flex flex-col">
              <span className="sr-only">Info</span>
              <p className="pb-2">
                If you were expecting to see my old web dev portfolio, I got bad news for you. I quit coding. Can't be doing this bullshit no more. I'm moving on to bigger and better things. Entry level tech jobs is dead either way, Chatgpt might as well take away these tech jobs. Wasted 2 years trying to get a job in this shit ass industry.
              </p>
              <p className="pb-2">
              Also I don't do Pokemon Brick Bronze or Youtube I have nothing to do with that no more I'm not the same person I once was I'm grown up now and I have not been doing that shit in years, stop asking about it and stop harassing me and grow up and move on, go get yourself a job. You losers would be millionaires by now if you put the same energy into getting money the same way you waste your life playing these stupid games and begging for me to come back. I owe you nothing.
              </p>
              <p className="pb-2">
                I am not associated nor am I related to Project Bronze or anything related to Pokemon Brick Bronze in any way. Anyone who is using my name claiming to be me to associate me with them or to accuse me of something or to make up rumors or lies about me is violating my rights and I will take legal action if necessary.
              </p>
              <p className="pb-2">
                If you want to stay in contact with me for some reason, you can find me on  <a className="text-blue-500"
                       href="https://linkedin.com/in/singharaj-usai"
                       rel="nofollow">
                        {" "}
                        LinkedIn
                    </a>
              </p>
              <p>
                Take this as a goodbye.
              </p>
            </div>
          </div>
          {randomPic && (
          <div className="mt-8 flex justify-center">
          <img 
            src={randomPic} 
            alt="Random funny pic" 
            className="w-64 h-64 object-contain rounded-lg"
          />
        </div>
        )}
        </div>
      </div>
    </section>
  );
};

export default About;