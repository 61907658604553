import React from "react";
import ExperienceItem from "./ExperienceItem";
import Revature from "../../../../images/revature-2.png"
import Walmart from "../../../../images/walmart.jpg"
import Roblox from "../../../../images/Roblox-Logo-2015-2017.png"
import YouTube from "../../../../images/youtube-video-logo-png-4.png"


const experienceData = [
    {
        id: 3,
        title: "Revature",
        description: (
            <>

                <p style={{ textIndent: "1rem" }}>
                    • Improved user experience on the Bank of America mobile banking app using Pegasystems, Babel, and Workbox PWAs to optimize compatibility across devices, allowing improved support for over <b>100,000+ older mobile devices</b>.
                </p>
                <p style={{ textIndent: "1rem" }}>
                    • Updated the Software Requirements (SRS) and Design Documentations (SDD), leading to improved team collaboration and project documentation.
                </p>
                <p style={{ textIndent: "1rem" }}>
                    • Collaborated with QA teams for thorough testing to identify and resolve bugs to deliver high-quality products to clients.
                </p>
            </>
        ),
        name: "Software Development Apprenticeship",
        date: "August 2023 - November 2023",
        logo: Revature,

    },
    {
        id: 2,
        title: "Walmart",
        description: (
            <>

                <p style={{ textIndent: "1rem" }}>
                    • My first real job. My time here was to collect money for student loans and relearn communication and social skills.
                </p>
                <p style={{ textIndent: "1rem" }}>
                    • Worked freights from the backroom and zone and stock inventory in the electronics department.
                </p>
                <p style={{ textIndent: "1rem" }}>
                    • Learned how to provide customer service, communication with team members, and help customers find their products.
                </p>
                <p style={{ textIndent: "1rem" }}>
                    • Transferable skills include sales, customer support, communication, team work, and problem-solving skills.
                </p>
            </>
        ),
        name: "Electronics Associate",
        date: "May 2023 - August 2023",
        logo: Walmart,

    },
    {
        id: 1,
        title: "YouTube",
        description: (
            <>

                <p style={{ textIndent: "1rem" }}>
                    • YouTube creator with a total of over <b>3 million subscribers</b> creating kid-friendly content primarily on the Roblox platform, with an average of over <b>500,000+ views per month</b> and <b>75% average view duration</b>.
                </p>
                <p style={{ textIndent: "1rem" }}>
                    • Utilized Tubebuddy and VidIQ for analytics and search ranking strategies to help optimize video results for the YouTube algorithm, consistently leading videos to be ranked in the top 10 of their respective niches.
                </p>
                <p style={{ textIndent: "1rem" }}>
                    • Used Adobe Photoshop, After Effects, and Vegas Pro to produce engaging content and design attractive thumbnails, leading to an average of <b>10% click-through ratio per million views</b> and maintaining <b>60% audience retention</b> for videos lasting over 10 minutes.
                </p>
                <p style={{ textIndent: "1rem" }}>
                    • Partnered creator with Union for Gamers MCN for creator networking opportunities, leading to sponsorship from companies such as OPRewards, Jazwares, and Swagbucks to maximize channel profitability and promote community engagement.
                </p>
            </>
        ),
        name: "Content Creator",
        date: "October 2015 - Present",
        logo: YouTube

    },
];
const Experience = () => {
    return (
        <section className="pb-4">
            <div className="flex justify-center flex-wrap ">
                {experienceData.map((experience, id) => (
                    <ExperienceItem experience={experience} key={id} />
                ))}
            </div>
        </section>
    );
};

export default Experience;