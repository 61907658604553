import React, { useContext } from "react";
import { ThemeContext } from "../../../ThemeProvider/ThemeProvider";
import funnyPic1 from "../../../images/this_secret_code_gave_mewtwo_in_pokemon_brick_bron_by_realmrbobbilly_deer8db.jpg";
import funnyPic2 from "../../../images/line.PNG";
import funnyPic3 from "../../../images/roblox_level_up_by_realmrbobbilly_dfsvzve.png";
import funnyPic4 from "../../../images/RobloxScreenShot01082012_155608039.jpg";
import funnyPic5 from "../../../images/i_bought_mewtwo_in_pokemon_brick_bronze_notclickba_by_realmrbobbilly_deer8cw.jpg";


const funnyPics = [
  { src: funnyPic1, alt: "Secret Code Mewtwo 2017", title: "Secret Code Mewtwo 2017" },
  { src: funnyPic2, alt: "Brick Bronze Line", title: "Brick Bronze Line" },
  { src: funnyPic3, alt: "Level Up", title: "Level Up" },
  { src: funnyPic4, alt: "2012 Roblox", title: "2012 Roblox" },
  { src: funnyPic5, alt: "Buying Mewtwo 2017", title: "Buying Mewtwo 2017" },

];

const Pictures = () => {
  const { isDarkMode } = useContext(ThemeContext);

  return (
    <section className={`md:mx-8 mb-3 px-6 py-2 ${isDarkMode ? 'bg-gray-900' : 'bg-gray-100'}`}>
      <div className="container mx-auto px-4">
        <h2 className={`text-3xl font-bold mb-8 ${isDarkMode ? 'text-white' : 'text-gray-800'}`}>Pictures</h2>
        <div className="w-full">
          <div className="flex p-4 mb-6 text-sm text-blue-800 border border-blue-300 rounded-lg bg-blue-50 dark:bg-gray-800 dark:text-blue-400 dark:border-blue-800"
            role="alert">
            <svg
              className="flex-shrink-0 inline w-4 h-4 mr-3 mt-[2px]"
              aria-hidden="true"
              xmlns="http://www.w3.org/2000/svg"
              fill="currentColor"
              viewBox="0 0 20 20"
            >
              <path d="M10 .5a9.5 9.5 0 1 0 9.5 9.5A9.51 9.51 0 0 0 10 .5ZM9.5 4a1.5 1.5 0 1 1 0 3 1.5 1.5 0 0 1 0-3ZM12 15H8a1 1 0 0 1 0-2h1v-3H8a1 1 0 0 1 0-2h2a1 1 0 0 1 1 1v4h1a1 1 0 0 1 0 2Z" />
            </svg>
            <div className="flex flex-col">
              <span className="sr-only">Info</span>
              <p className="pb-2">
                <span className="font-bold">Notice: </span>
This is a graveyard of pictures for times that will never come back.
              </p>
             
            </div>
          </div>
        </div>

        <div className="grid grid-cols-1 sm:grid-cols-2 lg:grid-cols-3 gap-8">
          {funnyPics.map((pic, index) => (
            <div key={index} className={`${isDarkMode ? 'bg-gray-800' : 'bg-white'} rounded-lg shadow-md overflow-hidden transition-transform duration-300 hover:scale-105`}>
              <div className="h-64 overflow-hidden">
                <img src={pic.src} alt={pic.alt} className="w-full h-full object-contain" />
              </div>
              <div className="p-4">
                <h3 className={`text-xl font-semibold mb-2 ${isDarkMode ? 'text-white' : 'text-gray-800'}`}>{pic.title}</h3>
              </div>
            </div>
          ))}
        </div>
      </div>
    </section>
  );
};

export default Pictures;